import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/runner/_work/designsystem-docs/designsystem-docs/src/templates/MDXPage.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Introduksjon`}</h2>
    <p>{`Det er viktig å kvalitetssikre implementeringen av Designsystemets komponenter i kontekst av siden.
Dette betyr at selv om komponentene er grunnleggende utviklet for å imøtekomme WCAG-standarden, vil det være nødvendig å sjekke at alt fungerer som det skal når de ulike komponentene benyttes.
På denne siden finner du en oversikt og gjennomgang av relevante WCAG testkriteria.
Ikke-relevante kriterier vil foreløpig ikke være inkludert på denne siden.`}</p>
    <p>{`Hver enkelt komponent vil lenke direkte til testkriterie de må testes for.`}</p>
    <h2>{`Prinsipp 1: Mulig å oppfatte`}</h2>
    <h3>{`Ikke-tekstlig innhold (1.1.1)`}</h3>
    <ul>
      <li parentName="ul">{`Inspiser koden.`}</li>
      <li parentName="ul">{`Hvis det ikke-tekstlige innholdet vil gi nyttig informasjon med kompenserende teknologi
som for eksempel skjermlesere, sjekk at det har tekstalternativ eller navn spesifisert i
koden (alt-tekst, aria-label, aria-labelledby, title, label, ...). Standard
HTML-punktlister trenger for eksempel ikke tekstalternativ for at kulepunktene skal presenteres riktig
med kompenserende teknologi. Se bare at standard ul- og li-elementer er brukt.`}</li>
      <li parentName="ul">{`Hvis det ikke-testlige innholdet er pynt eller ikke vil gi nyttig informasjon med
kompenserende teknologi, sjekk at det er tilstrekkelig skjult for kompenserende teknologi
i koden (alt-tekst som står tom, aria-hidden true, ...).`}</li>
      <li parentName="ul">{`Aktiver skjermleser.`}</li>
      <li parentName="ul">{`For ikke-tekstlig innhold som skal fanges opp av kompenserende teknologi, test at
tekstalternativet eller navnet leses opp av skjermleseren. For standard HTML-punktlister,
sjekk at kulepunktene leses opp (typisk sier skjermleser for eksempel punkttegn).`}</li>
      <li parentName="ul">{`For innhold som skal være skjult, test at dette ikke leses opp.`}</li>
    </ul>
    <h3>{`Informasjon og relasjoner (1.3.1)`}</h3>
    <h4>{`Heading`}</h4>
    <ul>
      <li parentName="ul">{`Inspiser koden.`}</li>
      <li parentName="ul">{`Sjekk om overskriften er kodet med enten standard HTML, spesifikt `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`h1`}</code>{` til og med `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`h6`}</code>{`.`}</li>
      <li parentName="ul">{`Kontrollér at overskriftsnivået i koden er det samme som det visuelle nivået til overskriften i grensesnittet.`}</li>
      <li parentName="ul">{`Sjekk at tittelen til tabellen aka tabelloverskriften ligger rett før tabellen uten tekst mellom tittel og tabell.`}</li>
      <li parentName="ul">{`Sjekk at tabeltittelen er kodet med `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`caption`}</code>{`-elementet for å knytte den til tabellen kodemessig.`}</li>
      <li parentName="ul">{`Kontrollér at tabelltittelen beskriver innholdet i tabellen på en god måte.`}</li>
    </ul>
    <h4>{`Tabeller`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Inspiser koden.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Kontrollér at tabellen er kodet med `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`table`}</code>{`-elementet og at overskriftscellene er kodet med `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`th`}</code>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`For komplekse tabeller kodet med med scope:`}</p>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`scope="col"`}</code>{` når overskriftscellen er overskrift for en kolonne.`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`scope="colgroup"`}</code>{` når overskriftscellen er overskrift for flere kolonner.`}</li>
          <li parentName="ul">{`scope="row" når overskriftscellen er overskrift for en rad.`}</li>
          <li parentName="ul">{`scope="rowgroup" når overskriftscellen er overskrift for flere rader.`}</li>
        </ul>
      </li>
    </ul>
    <h4>{`Lister`}</h4>
    <ul>
      <li parentName="ul">{`Inspiser koden. `}</li>
      <li parentName="ul">{`Se at komponenten er kodet som liste, med riktig listetype. Se også at de indre
elementene (`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`li`}</code>{`/`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`dt`}</code>{`/`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`dd`}</code>{`) ligger inne i de ytre elementene (`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`ul`}</code>{`/`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`ol`}</code>{`/`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`dl`}</code>{`).`}</li>
      <li parentName="ul">{`Aktiver skjermleser.`}</li>
      <li parentName="ul">{`Sjekk at det leses opp at komponenten er en liste og at alle liste-elementene leses
opp som en del av listen.`}</li>
    </ul>
    <h3>{`Meningsfylt rekkefølge (1.3.2)`}</h3>
    <p>{`Sikre at leserekkefølgen på innhold er den samme med CSS skrudd av som vanlig visning.
Dette betyr at rekkefølgen må være identisk, eller en rekkefølge som presenterer samme mening/funksjon.`}</p>
    <ul>
      <li parentName="ul">{`Kontrollér rekkefølgen av innholdet på nettsiden.`}</li>
      <li parentName="ul">{`Skru av CSS/stilark i nettleseren.`}</li>
      <li parentName="ul">{`Se om rekkefølgen eller formålet med innholdet er det samme som med CSS/stilark skrudd på.`}</li>
    </ul>
    <h3>{`Bruk av farge (1.4.1)`}</h3>
    <p>{`Observer komponenten i ulike tilstander. Hvis farge brukes som en måte til å
formidle informasjon til brukeren må du sjekke at informasjonen også gis på andre måter.`}</p>
    <ul>
      <li parentName="ul">{`Hvis informasjonen bare formidles med farger, sjekk at fargene skiller seg fra hverandre med en kontrast på minimum 3:1. `}</li>
      <li parentName="ul">{`Hvis brukerne må kunne identifisere en spesifikk farge for å forstå informasjonen, for eksempel om rammen på et inputfelt er grønn for gyldig eller rød for ugyldig, er det ikke nok med bare fargekontrasten. Da kreves det at vi har andre visuelle indikatorer i tillegg.`}</li>
    </ul>
    <h3>{`Kontrast (1.4.3)`}</h3>
    <p>{`Test fargen på teksten i komponenten mot bakgrunnsfargen til komponenten (hex-kodene). `}</p>
    <ul>
      <li parentName="ul">{`Bruk en kontrastsjekker som f.eks. Color Contrast Analyser (TPGi). `}</li>
      <li parentName="ul">{`Hvis fargene endrer seg i ulike tilstander av komponenten, for eksempel ved hover, husk å teste disse også.`}</li>
      <li parentName="ul">{`Tekststørrelsen i komponentene kan variere fordi den settes basert på brukerens nettleserinnstillinger. `}</li>
      <li parentName="ul">{`For å sikre at kravet er ivaretatt på alle ulike tekststørrelser baserer vi testene på det høyeste kontrastkravet (4.5:1).`}</li>
    </ul>
    <h3>{`Endring av tekststørrelse (1.4.4)`}</h3>
    <p>{`Tekst kan bli endret til 200 % størrelse uten tap av innhold eller funksjon.`}</p>
    <ul>
      <li parentName="ul">{`Inspiser koden. `}</li>
      <li parentName="ul">{`Sjekk at all tekst i komponenten er spesifisert i et skalerbart format, for eksempel rem. Dette gjelder også komponenten om du definerer høyde.`}</li>
      <li parentName="ul">{`Øk tekststørrelsen til 200% i nettleser-innstillingene. Metode avhenger av nettleser.
Hvis du bruker Chrome, øk tekststørrelsen på html-taggen til 200%. Det er viktig at endringen skjer på HTML-taggen, om du zoomer i body kan du potensielt få feil resultat.`}</li>
      <li parentName="ul">{`Se at teksten i komponenten endrer størrelse.`}</li>
      <li parentName="ul">{`Se at alt innhold og all funksjonalitet i komponenten fortsatt er tilgjengelig og
forståelig (innhold overlapper ikke annet innhold, innhold blir ikke kuttet så det
ikke er synlig).`}</li>
      <li parentName="ul">{`Sjekk i både desktop og mobilvisning. Bredde 320px kan brukes for å teste mobilvisning.`}</li>
    </ul>
    <p>{`Ved bruk av ord med litt lengde, i mobilvisning, kan innhold flyte ut over containeren til komponenten samt skjermen og intodusere horisontal skroll. Dette er et tolkningsspørsmål og skal ikke være brudd på krav 1.4.4. siden innholdet fortsatt er tilgjengelig og forståelig. Det anbefales likevel å forsøke å unngå dette så langt det er mulig.`}</p>
    <h3>{`Bilde av tekst (1.4.5)`}</h3>
    <p>{`Unngå bilde av tekst med mindre dette kan direkte tilpasses av brukeren til deres behov.`}</p>
    <p>{`Logoer eller lignende med tilfredsstillende alt-tekst er akseptabelt.`}</p>
    <h2>{`Prinsipp 2: Mulig å betjene`}</h2>
    <h3>{`Tastatur (2.1.1)`}</h3>
    <p>{`Alt innhold skal være tilgjengelig med tastaturnavigasjon.`}</p>
    <p>{`Bruk tab-tasten til å navigere deg gjennom nettsiden og sørg for at interaktive elementer kan brukes med enter eller mellomromstasten.`}</p>
    <h3>{`Ingen tastaturfelle (2.1.2)`}</h3>
    <p>{`Det skal være mulig å navigere seg ut av interaktive elementer når man benytter tastaturnavigasjon.`}</p>
    <ul>
      <li parentName="ul">{`Bruk tastaturet og naviger til komponenten med tab-tasten.`}</li>
      <li parentName="ul">{`Naviger deg videre til neste element på siden for å sjekke at fokuset ikke er «fanget» i komponenten.`}</li>
    </ul>
    <h3>{`Hoppe over blokker (2.4.1)`}</h3>
    <p>{`Brukeren skal ha mulighet til å hoppe over gjentagende informasjon (typ header meny) med "hopp til hovedinnhold" og "hopp til navigasjon" lenker.`}</p>
    <h3>{`Sidetitler (2.4.2)`}</h3>
    <p>{`Nettsider skal ha sidetitler som beskriver den aktuelle sidens emne eller formål.`}</p>
    <ul>
      <li parentName="ul">{`Inspiser koden.`}</li>
      <li parentName="ul">{`Sjekk at `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`title`}</code>{` elementet inneholder en tittel som korrekt beskriver hovedinnholdet eller hovedformålet til siden.`}</li>
      <li parentName="ul">{`Dette gjelder også for digitale dokumenter.`}</li>
    </ul>
    <h3>{`Fokusrekkefølge (2.4.3)`}</h3>
    <p>{`Interaktive elementer skal ha en logisk tastaturrekkefølge på samme måte som den visuelle rekkefølgen.`}</p>
    <ul>
      <li parentName="ul">{`Naviger deg gjennom nettsiden med tab-tasten på tastaturet.`}</li>
      <li parentName="ul">{`Sjekk at de interaktive elementene er tilgjengelige med tastatur.`}</li>
      <li parentName="ul">{`Sjekk at rekkefølgen på de interaktive elementene henger sammen med den visuelle rekkefølgen i grensesnittet.`}</li>
    </ul>
    <h3>{`Formål med lenke i kontekst (2.4.4)`}</h3>
    <h3>{`Flere måter (2.4.5)`}</h3>
    <h3>{`Overskrifter og ledetekster (2.4.6)`}</h3>
    <h3>{`Synlig fokus (2.4.7)`}</h3>
    <h2>{`Prinsipp 3: Forståelig`}</h2>
    <h3>{`Språk på siden (3.1.1)`}</h3>
    <h3>{`Språk på deler av innhold (3.1.2)`}</h3>
    <h3>{`Fokus (3.2.1)`}</h3>
    <h3>{`Inndata (3.2.2)`}</h3>
    <h3>{`Konsekvent navigering (3.2.3)`}</h3>
    <h3>{`Konsekvent identifikasjon (3.2.4)`}</h3>
    <h3>{`Identifikasjon av feil (3.3.1)`}</h3>
    <h3>{`Ledetekster eller instruksjoner (3.3.2)`}</h3>
    <h3>{`Forslag ved feil (3.3.3)`}</h3>
    <h3>{`Forhindring av feil (3.3.4)`}</h3>
    <h2>{`Prinsipp 4: Robust`}</h2>
    <h3>{`Parsing/oppdeling (4.1.1)`}</h3>
    <h3>{`Navn, rolle, verdi (4.1.2)`}</h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      